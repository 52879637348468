.input-field {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #333;
}

.input-field:focus {
    outline: none;
    border-color: #333;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}