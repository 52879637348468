.bgImg{
    position: relative;
    background-image: url("../../../../../assets/homePage/sachin_just_drive_\(Home\ Banner\).webp");
    background-size: cover;
    background-position:center; 
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.head{
    z-index: 99;
}

.bgImg::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.48);
    z-index: 1;

}

.bgImg > div {
    position: relative;
    z-index:1;
}

/* .bgImg > div > section > button {
    z-index: 99;
} */

@media (max-width: 1000px) {
    .bgImg {
            position: relative;
            background-image: url("../../../../../assets/homePage/sachin_just_drive_\(Home\ Banner\).webp");
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 90vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
}